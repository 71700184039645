import React, { Component, Suspense, useEffect } from "react";
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
import { ToastContainer } from "react-toastify";
import feRoutes from "./routes/frontend";
import logo from './assets/frontend/img/loading.gif';
import "react-toastify/dist/ReactToastify.css";
import "./assets/frontend/css/global.css";
import "./assets/frontend/css/bg.css";

const MyMap = React.lazy(() => import("./components/frontend/user/MyMap"));
const Locations = React.lazy(() => import("./components/frontend/user/Locations"));
const NotFound = React.lazy(() => import("./components/404"));

const DefaultLayout = () => {
  const loading = () => (
    <div className="animated fadeIn pt-3 text-center">
      <img title="" className="loader" alt="loader" src={logo} />
      
    </div>
  );

  return (
    <Router>
      <Routes>
        <Route path="/" element={<MyMap />} />
        <Route path="/leaflet/location" element={<Locations />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </Router>
  );
}

export default DefaultLayout;
